import {Inject, Injectable} from '@angular/core';
import { ResourceService } from "./resource.service";
import { Credentials } from "@model";
import {DOCUMENT} from '@angular/common';
import {Observable, of} from 'rxjs';

const API_ENDPOINT = "login";

@Injectable()
export class LoginService {

  constructor(
    private resourceService: ResourceService<Credentials>,
    @Inject(DOCUMENT) private document
  ) {
  }

  login(credentials: Credentials): Observable<Credentials> {
    if (!credentials.username || !credentials.password) {
      return of(null);
    }
    return this.resourceService.post(API_ENDPOINT, credentials);
  }

  redirect() {
    const urlParams = new URLSearchParams(window.location.search);
    const redirectUrl = urlParams.get('redirect_url');
    this.document.location.href = redirectUrl;
  }

  hasRedirect() {
    const urlParams = new URLSearchParams(window.location.search);
    return !!urlParams.get('redirect_url')
  }
}
