export interface Resource {
  id: string;
  registrationName?: string;
  resourceId: string;
  registrationId: string;
  archived?: boolean;
}

export class Resource {
  constructor() {
    this.id = null;
    this.registrationName = null;
    this.resourceId = null;
    this.registrationId = null;
    this.archived = null;
  }
}
