import {Component, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Account} from '@model';

@Component({
  selector: "login-home",
  templateUrl: "./admin.component.html",
  styleUrls: ["./admin.component.scss"]
})
export class AdminComponent implements OnInit {

  accounts: Account[] = [];

  resetUserPasswordForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
  });

  newUserForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    phone: new FormControl('', Validators.required),
    homeApp: new FormControl('', Validators.required),
    timezone: new FormControl('', Validators.required),
    locale: new FormControl('', Validators.required),
    preferredAccount: new FormControl('', Validators.required),
  })

  constructor(private route: ActivatedRoute) {  }

  ngOnInit() {
    this.route.data.subscribe(data => {
      this.accounts = data['updates'];
    })
  }
}
