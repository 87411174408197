import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { AdminAuthGuard, LoginAuthGuard } from "../guard";
import { LoginModule, AdminComponent, LoginComponent } from "@view";
import { PermissionEnum } from '@model';
import { AdminResolver } from '@resolver';

enum Routing {
  admin = "admin",
  doc = "doc",
  root = "",
}

const routerOptions: ExtraOptions = {
  onSameUrlNavigation: "reload",
  enableTracing: false
};

const routes: Routes = [
  {
    path: Routing.admin,
    component: AdminComponent,
    canActivate: [AdminAuthGuard],
    data: { requiredPermissions: [PermissionEnum.update] },
    resolve: { updates: AdminResolver },
  },
  {
    path: Routing.root,
    component: LoginComponent,
    pathMatch: "full",
    canActivate: [LoginAuthGuard],
  },
  {
    path: Routing.doc,
    redirectTo: '/doc/index.html',
  },
  {
    path: "**",
    redirectTo: "/"
  }
];

@NgModule({
  imports: [
    LoginModule,
    RouterModule.forRoot(routes, routerOptions)
  ],
  exports: [RouterModule],
  providers: [
    AdminAuthGuard,
    LoginAuthGuard,
    AdminResolver
  ]
})
export class CoreRoutingModule {
}
