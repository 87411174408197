import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';
import { ResourceService } from "./resource.service";
import { Locale } from 'src/app/shared/model/locale.model';

const LOCALES_ENDPOINT = "locales";

@Injectable()
export class LocaleService {
  private locales$: Observable<Locale[]>;

  constructor(private resourceService: ResourceService<Locale[]>) {
  }

  getLocales(): Observable<Locale[]> {
    if (!this.locales$) {
      this.locales$ = this.resourceService.get(LOCALES_ENDPOINT).pipe(
        map(value => Object.values(value)));
    }
    return this.locales$;
  }
}
