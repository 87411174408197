<div>
  <div class='form-card'>
    <div data-cy='login-welcome'
         class='form-card-header background-teal-sunrise'>
      <div class='form-card-title' data-cy='welcome-title-1'>
        Create a new User
      </div>
      <ng-container>
        <div class='form-card-subtitle' data-cy='welcome-title-2'>
          Please enter the following information:
        </div>
      </ng-container>
    </div>

    <div class='form-card-body background-secondary' data-cy='credentials-container'>
      <div class='form-card-body-wrapper flex-auto'>
        <form [formGroup]='newUserForm' class='new-user-form'>

          <div data-cy='user-form-fields items-start'>
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>username:</span>
              <input formControlName='username' [maxLength]='40' type='text' />
            </div>
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>email:</span>
              <input formControlName='email' [maxLength]='40' type='text' />
            </div>
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>phone:</span>
              <input formControlName='phone' [maxLength]='40' type='text' />
            </div>
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>first name:</span>
              <input formControlName='firstName' [maxLength]='40' type='text' />
            </div>
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>last name:</span>
              <input formControlName='lastName' [maxLength]='40' type='text' />
            </div>
            <!-- Home App -->
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>home app:</span>
              <select
                formControlName="homeApp"
                data-automation="homeApp"
                placeholder="Select Home Application"
                class="truncated"
              >
                <option *ngFor="let application of applications" [value]="application.id">
                  {{ application.displayLabel }}
                </option>
              </select>
            </div>
            <!-- Time Zone -->
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>timezone:</span>
              <select
                formControlName="timezone"
                data-automation="timezone"
                placeholder="Select Timezone"
                class="truncated"
              >
                <option *ngFor="let timezone of timezones" [value]="timezone.id">
                  {{ timezone.displayLabel }}
                </option>
              </select>
            </div>
            <!-- Locale -->
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>locale:</span>
              <select
                formControlName="locale"
                data-automation="locale"
                placeholder="Select Locale"
                class="truncated"
              >
                <option *ngFor="let locale of locales" [value]="locale.id">
                  {{ locale.displayLabel }}
                </option>
              </select>
            </div>
            <!-- Preferred Account -->
            <div class='credential-row'>
              <span class='credential-label mat-form-field-label'>preferred account:</span>
              <select
                formControlName="preferredAccount"
                data-automation="preferred-account"
                placeholder="Select an Account"
                class="truncated"
              >
                <option *ngFor="let account of accounts" [value]="account.entityId">
                  {{ account.displayLabel }}
                </option>
              </select>
            </div>

          </div>
        </form>
        <ng-container *ngIf='generatedPassword?.length > 0'>
          <div data-cy='generated-password'
               class='credential-row'>
            <span class='credential-label mat-form-field-label'>new password:</span>
            <span class='generated-password-value'>{{ generatedPassword }}</span>
          </div>
        </ng-container>
      </div>

      <div data-cy='login-controls-container'
           class='login-controls-container text-center'>
        <span class='submit-new-user-button'>
          <button class="x-btn x-btn-primary" (click)='submitNewUser()'>Create User</button>
        </span>
        <span>
          <button class="x-btn x-btn-secondary" (click)='goBack()'>Cancel</button>
        </span>
      </div>
    </div>
  </div>
</div>
