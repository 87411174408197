import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { CookieService, PermissionService } from '@service';

@Injectable()
export class AdminAuthGuard  {

  constructor(private permissionService: PermissionService,
              private cookieService: CookieService) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const session = this.cookieService.getDesSession();
    const isAllowed = session && session.length > 0;
    if (!isAllowed) {
      document.location.href = document.location.origin;
    }

    return isAllowed;

    // const { requiredPermissions } = route.data;
    // const hasPermissions = this.permissionService.hasPermission(requiredPermissions);
    //
    // if (!hasPermissions) {
    //   document.location.href = document.location.origin;
    //   return false;
    // } else {
    //   return true;
    // }
  }
}
