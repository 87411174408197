import { Component } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { passwordValidator } from '../../shared/validator/password.validator';

@Component({
  selector: "login-component",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent {

  loginForm: FormGroup = new FormGroup({
    username: new FormControl('', Validators.required),
    oldPassword: new FormControl('', [Validators.required, passwordValidator()]),
    password: new FormControl('', [Validators.required, passwordValidator()]),
  });

  constructor() {}
}
