import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ResourceService } from './resource.service';
import { Timezone } from 'src/app/shared/model/timezone.model';

const TIMEZONES_ENDPOINT = 'timezones';

@Injectable()
export class TimezoneService {
  private timezones$: Observable<Timezone[]>;

  constructor(private resourceService: ResourceService<Timezone[]>) {}

  getTimezones(): Observable<Timezone[]> {
    if (!this.timezones$) {
      this.timezones$ = this.resourceService.get(TIMEZONES_ENDPOINT);
    }
    return this.timezones$;
  }
}
