import { DOCUMENT } from "@angular/common";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CookieService } from "@service";
import { Router, ActivatedRoute } from '@angular/router';

@Injectable()
export class AuthHttpInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: CookieService,
    private router: Router,
    private route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has("Authorization") || request.url.indexOf('/api/v1/login') >= 0) {
      return next.handle(request);
    } else {
      //redirect to root
      if (!this.cookieService.getDesSession()) {
        this.document.location.href = this.document.location.origin;
      }

      // add auth header
      const requestAuth = request.clone({
        headers: request.headers.set('Authorization', this.cookieService.getDesSession())
      });

      const redirectUrl = this.document.location.hash['redirect_url'];
      if (redirectUrl) {
        this.document.location.href = redirectUrl;
      }

      return next.handle(requestAuth);
    }
  }
}
