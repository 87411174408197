import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { CookieService } from "@service";
import { ResourceService } from "@service";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@Component({
  selector: 'login-root',
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {

  constructor(
    private router: Router,
    private cookieService: CookieService,
    private resourceService: ResourceService<string>,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "noData",
      this.domSanitizer.bypassSecurityTrustResourceUrl("/assets/svg-icons/no-data.svg")
    );
  }
}
