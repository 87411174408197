import { NgModule } from "@angular/core";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { AppComponent } from "./app.component";
import { AlertModule } from "@component";
import { CoreModule } from "@core";
import { LoginModule } from "@view";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { FormsModule } from "@angular/forms";
import { MatNativeDateModule } from '@angular/material/core';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';


@NgModule({
  declarations: [AppComponent],
  imports: [
    AlertModule,
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    MatButtonModule,
    MatInputModule,
    MatIconModule,
    LoginModule,
    FormsModule,
    MatFormFieldModule,
    MatNativeDateModule,
  ],
  exports: [BrowserModule, BrowserAnimationsModule, CoreModule],
  bootstrap: [AppComponent],
})
export class AppModule {
}
