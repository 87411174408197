import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function passwordValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {

    if (control.value && control.value.length > 3) {
      const regex = new RegExp(/^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.{12,}).*)$/);
      const match = regex.test(control.value);
      return !match ? { invalidPassword: { value: control.value } } : null;
    }

    return null
  };
}
