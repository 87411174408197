import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from "./resource.service";
import { DesEntityModel } from '@model';

const ACCOUNTS_ENDPOINT = "entities";

@Injectable()
export class DesEntityService {
  private entities$: Observable<DesEntityModel[]>;

  constructor(private resourceService: ResourceService<{ [string: string]: DesEntityModel}>) {}

  getEntities(): Observable<DesEntityModel[]> {
    if (!this.entities$) {
      this.entities$ = this.resourceService
        .get(ACCOUNTS_ENDPOINT)
        .pipe(map(value => Object.values(value)));
    }
    return this.entities$;
  }
}
