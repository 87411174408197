import { Injectable } from "@angular/core";
import { ResourceService } from "./resource.service";
import { Credentials } from "@model";

const usersResetEndpoint = "users/reset-password";
const adminResetEndpoint = "admins/users/reset-password";

@Injectable()
export class PasswordService {

  constructor(private resourceService: ResourceService<Credentials | string>) {
  }

  resetPassword(credentials: Credentials): any {
    if (!credentials.username || !credentials.oldPassword || !credentials.password) {
      return;
    }
    return this.resourceService.post(usersResetEndpoint, credentials);
  }

  generatePassword(credentials: Credentials): any {
    if (!credentials) {
      return;
    }
    return this.resourceService.post(adminResetEndpoint, credentials);
  }
}
