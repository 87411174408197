import { Injectable } from "@angular/core";
import { ResourceService } from "./resource.service";
import { User } from '@model';

const API_ENDPOINT = "users";

@Injectable()
export class UserService {

  constructor(private resourceService: ResourceService<User>) {
  }

  createNewUser(user: User): any {
    if (!user.username
      || !user.email
      || !user.phone
      || !user.homeApp
      || !user.timezone
      || !user.locale
      || !user.preferredAccount) {
      return;
    }
    return this.resourceService.post(API_ENDPOINT, user);
  }

  getUserDetails() {
    return this.resourceService.get(`${API_ENDPOINT}/details`);
  }
}
