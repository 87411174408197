import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from "./resource.service";
import { Application } from 'src/app/shared/model/application.model';

const APPLICATIONS_ENDPOINT = "applications";

@Injectable()
export class ApplicationService {
  private applications$: Observable<Application[]>;

  constructor(private resourceService: ResourceService<{ [string: string]: Application}>) {}

  getApplications(): Observable<Application[]> {
    if (!this.applications$) {
      this.applications$ = this.resourceService.get(APPLICATIONS_ENDPOINT).pipe(
        map(value => Object.values(value)));
    }
    return this.applications$;
  }
}
