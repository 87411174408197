<div>
  <div class='form-card flex-row left'>
      <div data-cy='login-welcome'
         class='form-card-header background-teal-sunrise'>
      <div class='form-card-title' data-cy='welcome-title-1'>
        Welcome to Calibrant!
      </div>
      <ng-container *ngIf='!resettingPassword'>
        <div class='form-card-subtitle' data-cy='welcome-title-2'>
          Please enter your username and password
        </div>
      </ng-container>
      <ng-container *ngIf='resettingPassword'>
        <div class='form-card-subtitle' data-cy='welcome-title-2'>
          Please enter your username, old password and password
        </div>
      </ng-container>
    </div>

    <ng-container *ngIf="!loggedIn">
      <div class='form-card-body background-secondary' data-cy='credentials-container'>
        <div class='form-card-body-wrapper'>
          <form [formGroup]='loginForm' class='login-form'>

            <div data-cy='items-start'>
              <div class='credential-row'>
                <span class='credential-label mat-form-field-label'>username:</span>
                <input formControlName='username' [maxLength]='40' type='text' />
                <span></span>
              </div>
              <div class='credential-row' *ngIf='resettingPassword'>
                <span class='credential-label mat-form-field-label'>old password: </span>
                <input
                    formControlName='oldPassword'
                    type="{{ oldPasswordShown ? 'text' : 'password' }}"
                    [maxLength]='40' />
                <span class='show-password link'
                      (click)='toggleShowOldPassword()'>{{ oldPasswordShown ? 'hide' : 'show' }}
              </span>
              </div>
              <div class='credential-row'>
                <span class='credential-label mat-form-field-label'>password: </span>
                <input formControlName='password' type="{{ passwordShown ? 'text' : 'password' }}" [maxLength]='40' />
                <span class='show-password link' (click)='toggleShowPassword()'>{{ passwordShown ? 'hide' : 'show' }}</span>
              </div>
              <div *ngIf="passwordField.errors?.['invalidPassword'] && resettingPassword" class="error">
                Passwords must contain at least 1 uppercase, 1 lowercase, 1 number and a minimum of 12 characters.
              </div>
              <span class='change-password-button' *ngIf='!resettingPassword'>
                <span>Having Trouble Logging In? </span>
                <a (click)='toggleResetPassword()'>Reset Password</a>
            </span>
            </div>
            <div data-cy='login-controls-container'
                 class='form-card-body text-center'>
              <span class='login-button' *ngIf='!resettingPassword'>
                <button class="x-btn x-btn-primary" (click)='login()' type="submit">Login</button>
              </span>
              <span class='submit-password-button' *ngIf='resettingPassword'>
                <button class="x-btn x-btn-primary" (click)='resetPassword()' [disabled]="!passwordField.valid || !passwordValid()" type="submit">Submit Password</button>
              </span>
              <span class='cancel-button' *ngIf='resettingPassword'>
                <button class="x-btn x-btn-secondary" (click)='toggleResetPassword()'>Cancel</button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="loggedIn">
      <div class='form-card-body background-secondary'>
        <div>You are now logged in...</div>
        <ng-container *ngIf="isAdmin">
          <div class="pad-top-20">
            <a href="/admin">Go to Admin</a>
          </div>
        </ng-container>
        <ng-container *ngIf="app?.displayLabel">
          <div class="pad-top-20">
            <a href="{{app.url}}">Go to {{ app.displayLabel }}</a>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
