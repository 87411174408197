import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule, Optional, SkipSelf } from '@angular/core';
import {AuthHttpInterceptor, MockHttpInterceptor, ErrorInterceptor} from './interceptor';
import { CoreRoutingModule } from './routing';
import {
  AccountService,
  ApplicationService,
  CookieService,
  GlobalAlertService,
  LoaderService, LocaleService,
  LoginService,
  PasswordService,
  PermissionService,
  ResourceService,
  RouterService, TimezoneService, UserService,
  DesEntityService
} from '@service';

const initRouterCheck = (routerService: RouterService) => (): Promise<void> =>
  routerService.initRouterCheck();

@NgModule({
  imports: [
    CoreRoutingModule,
    HttpClientModule,
  ],
  exports: [
    CoreRoutingModule,
  ],
  providers: [
    AccountService,
    ApplicationService,
    CookieService,
    GlobalAlertService,
    LoaderService,
    LocaleService,
    LoginService,
    ResourceService,
    RouterService,
    PasswordService,
    PermissionService,
    TimezoneService,
    UserService,
    DesEntityService,
    {
      provide: APP_INITIALIZER,
      useFactory: initRouterCheck,
      deps: [RouterService],
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
})
export class CoreModule {
  // Make sure CoreModule is imported only by one NgModule the LoginModule
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in LoginModule');
    }
  }
}
