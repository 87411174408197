import { DOCUMENT } from "@angular/common";
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpEventType,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError, finalize, map } from "rxjs/operators";
import { GlobalAlertService } from "@service";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private globalAlertService: GlobalAlertService,
    @Inject(DOCUMENT) private document: Document
  ) {
  }

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    let lastResponse;
    let error;

    return next.handle(request).pipe(
      map(event => {
        lastResponse = event;
        return event;
      }),
      catchError((err: unknown) => {
        error = err;
        if (!(err instanceof HttpErrorResponse)) {
          return throwError(() => new Error(err.toString()));
        }
        switch (err.status) {
          case 401:
          case 403:
            this.document.location.href = this.document.location.origin;
            break;
        }

        const errorMessage = `${err.error?.code}: ${err.error?.message} - ${err.error?.detail}`;
        this.globalAlertService.setError(errorMessage, 10000);
        return throwError(() => new Error(errorMessage));
      }),
      finalize(() => {
        if (lastResponse.type === HttpEventType.Sent && !error) {
          // last response type was 0, and we haven't received an error
        }
      })
    );
  }

  redirectToForbidden(): void {
    const forbiddenPath = "forbidden/index.html";
    this.document.location.href = `${document.location.origin}/${forbiddenPath}?type=forbidden`;
  }
}
