import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import {ApplicationService, CookieService} from '@service';

@Injectable()
export class LoginAuthGuard {
  constructor(private cookieService: CookieService,
              private applicationService: ApplicationService,
              private router: Router) {
  }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const hasSession = this.cookieService.getDesSession();
    const homeApp = this.cookieService.getCookie('home_app');

    if (!hasSession) {
      return true;
    } else {
      const urlParams = new URLSearchParams(window.location.search);
      const redirectUrl = urlParams.get('redirect_url');
      if (redirectUrl) {
        window.location.href = redirectUrl;
        return false;
      }

      if (homeApp) {
        this.applicationService.getApplications().subscribe((apps) => {
          const appUrl = apps.find((app) => app.id === homeApp)?.url;
          if (appUrl) {
            window.location.href = appUrl;
          }
          return false
        });
      }
    }
    return true;
  }
}
