import { Component, Input, OnDestroy } from '@angular/core';
import { GlobalAlertService, LocaleService, TimezoneService, UserService } from '@service';
import { FormGroup } from '@angular/forms';
import { User } from '@model';
import { Timezone } from 'src/app/shared/model/timezone.model';
import { Locale } from 'src/app/shared/model/locale.model';
import { Application } from 'src/app/shared/model/application.model';
import { ApplicationService } from 'src/app/core/service/application.service';
import { Account } from 'src/app/shared/model/account.model';
import { AccountService } from 'src/app/core/service/account.service';
import {Router} from '@angular/router';

@Component({
  selector: 'login-new-user-form',
  templateUrl: './new-user-form.component.html',
  styleUrls: ['./new-user-form.component.scss'],
})
export class NewUserFormComponent implements OnDestroy {

  generatedPassword: string = '';
  subscribers = [];
  timezones: Timezone[] = [];
  locales: Locale[] = [];
  applications: Application[] = [];
  passwordShown = false;

  @Input() newUserForm: FormGroup;
  @Input() accounts: Account[];

  constructor(
    private accountService: AccountService,
    private applicationService: ApplicationService,
    private globalAlertService: GlobalAlertService,
    private localeService: LocaleService,
    private timezoneService: TimezoneService,
    private userService: UserService,
    private route: Router,
  ) {
    const timezoneSubscriber = this.timezoneService.getTimezones().subscribe(timezones => this.timezones = timezones);
    const localeSubscriber = this.localeService.getLocales().subscribe(locales => this.locales = locales);
    const applicationSubscriber = this.applicationService.getApplications().subscribe(applications => this.applications = applications);
    this.subscribers.push(timezoneSubscriber, localeSubscriber, applicationSubscriber);
  }

  get user(): User {
    return { ...this.newUserForm.getRawValue() } as User;
  }

  get username(): string {
    return this.newUserForm.get('username').value;
  }

  get password(): string {
    return this.newUserForm.get('password').value;
  }

  get firstName(): string {
    return this.newUserForm.get('firstName').value;
  }


  get lastName(): string {
    return this.newUserForm.get('lastName').value;
  }

  get email(): string {
    return this.newUserForm.get('email').value;
  }

  get phone(): string {
    return this.newUserForm.get('phone').value;
  }

  get homeApp(): string {
    return this.newUserForm.get('homeApp').value;
  }

  get timezone(): string {
    return this.newUserForm.get('timezone').value;
  }

  get locale(): string {
    return this.newUserForm.get('locale').value;
  }

  get preferredAccount(): string {
    return this.newUserForm.get('preferredAccount').value;
  }

  ngOnDestroy() {
    this.subscribers.forEach(subscriber => subscriber.unsubscribe());
  }

  toggleShowPassword() {
    this.passwordShown = !this.passwordShown;
  }

  submitNewUser() {
    if (!this.username
      || !this.email
      || !this.phone
      || !this.firstName
      || !this.lastName
      || !this.homeApp
      || !this.timezone
      || !this.locale
      || !this.preferredAccount) {
      this.globalAlertService.setError('all fields are required');
    } else {
      try {
        this.userService.createNewUser(this.user).subscribe((results) => {
          this.globalAlertService.setSuccess(`${this.username} successfully created.  Please save the generated password.`)
          if (results?.data?.password) {
            this.generatedPassword = results.data.password;
          }
          else {
            this.globalAlertService.setError('An error occurred user password was not returned: ', results.message);
          }
        });
      } catch(err) {
        this.globalAlertService.setError(`There was a problem creating the user.  ${err}`);
      }
    }
  }

  goBack() {
    this.route.navigate(['/']);
  }
}
