import { Component, Input } from '@angular/core';
import { GlobalAlertService, PasswordService } from '@service';
import { FormGroup } from '@angular/forms';
import { Credentials } from '@model';
import {Router} from '@angular/router';

@Component({
  selector: 'login-generate-password-form',
  templateUrl: './generate-password-form.component.html',
  styleUrls: ['./generate-password-form.component.scss'],
})
export class GeneratePasswordFormComponent {

  generatedPassword: string = '';

  @Input() resetUserPasswordForm: FormGroup;

  constructor(
    private passwordService: PasswordService,
    private globalAlertService: GlobalAlertService,
    private route: Router,
  ) {}

  get credentials(): Credentials {
    return { ...this.resetUserPasswordForm.getRawValue() } as Credentials;
  }

  generatePassword(): void {
    try {
      this.passwordService.generatePassword(this.credentials).subscribe(results => {
        if (results?.data?.password) {
          this.generatedPassword = results.data.password;
          this.globalAlertService.setSuccess("Password successfully generated.  Please copy the generated password.")
        }
        else {
          this.globalAlertService.setError('An error occurred generating a password: ', results.message);
        }
      });
    } catch(err) {
      this.globalAlertService.setError('An error occurred generating a password: ', err);
    }
  }

  goBack() {
    this.route.navigate(['/']);
  }
}
