import { Injectable } from '@angular/core';

import { Observable, map, forkJoin } from 'rxjs';
import { AccountService } from '@service';
import { Account, DesEntityModel } from '@model';
import { DesEntityService } from '@service';

@Injectable()
export class AdminResolver  {

  constructor(
    private accountService: AccountService,
    private desEntityService: DesEntityService
  ) {}

  resolve(): Observable<Account[]> {
    const accounts$: Observable<Account[]> = this.accountService
      .getAccounts()

    const entities$: Observable<DesEntityModel[]> = this.desEntityService
      .getEntities()

    const accountsMerged$: Observable<[Account[], DesEntityModel[]]> = forkJoin([accounts$, entities$]);

    return accountsMerged$.pipe(
      map(([accounts, entities]) => {
        return accounts
          .reduce((acc, account) =>  {
            const acctEntity = entities.find((entity) => entity.extId === account.id);
            if (acctEntity) {
              acc.push({ ...account, entityId: acctEntity.id })
            }

            return acc;
          }, []);
      })
    )
  }
}
