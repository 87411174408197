import { DOCUMENT } from "@angular/common";
import { Inject, Injectable } from "@angular/core";

/**
 * Wrapping the translateService for Enel purposes, including fallback rules, branding, etc
 */
@Injectable()
export class CookieService {
  cookies = {};

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.document.cookie.split(";").map(variable => {
      const variableParts = variable.split("=");
      this.cookies[variableParts[0].trim()] = variableParts[1];
    });
  }

  // Need to import the angular cookie service
  getCookie(cname: string | number, defaulter: unknown = null): string {
    this.document.cookie.split(";").map(variable => {
        const variableParts = variable.split("=");
        this.cookies[variableParts[0].trim()] = variableParts[1];
    });
    return this.cookies[cname] || defaulter;
  }

  getDesSession(): string {
    return this.cookies['des_session'] || this.getCookie("des_session") ;
  }
}
