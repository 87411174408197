<div>
  <div class='form-card flex-row left'>
    <div data-cy='admin-welcome'
         class='form-card-header background-teal-sunrise'>
      <div class='form-card-title' data-cy='welcome-title-1'>
        Reset User password
      </div>
      <div class='form-card-subtitle' data-cy='welcome-title-2'>
        Please enter a known Username to generate a password for them.
      </div>
    </div>

    <div class='form-card-body background-secondary' data-cy='login-container'>
      <div class='form-card-body-wrapper flex-auto'>
        <form [formGroup]='resetUserPasswordForm' class='admin-form'>

          <div data-cy='admin-form-fields items-start'>
            <div class='username credential-row'>
              <span class='credential-label mat-form-field-label'>username:</span>
              <input formControlName='username' [maxLength]='40' type='text' />
            </div>
          </div>
        </form>
        <ng-container *ngIf='generatedPassword?.length > 0'>
          <div data-cy='generated-password'
               class='generated-password credential-row'>
              <span class='credential-label mat-form-field-label'>new password:</span>
              <span class='generated-password-value'>{{ generatedPassword }}</span>
          </div>
        </ng-container>
      </div>

      <div data-cy='admin-user-password-controls-container'
           class='admin-user-password-controls-container text-center'>
        <span class='generate-password-button'>
          <button class="x-btn x-btn-primary" (click)='generatePassword()'>generate</button>
        </span>
        <span>
          <button class="x-btn x-btn-secondary" (click)='goBack()'>Cancel</button>
        </span>
      </div>
    </div>
  </div>
</div>
