import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";
import { AdminComponent } from "src/app/view/admin/admin.component";
import { RouterModule } from "@angular/router";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { ReactiveFormsModule } from '@angular/forms';
import { GeneratePasswordFormModule, NewUserFormModule } from '@component';

@NgModule({
  declarations: [AdminComponent],
  exports: [AdminComponent],
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    RouterModule,
    MatButtonModule,
    ReactiveFormsModule,
    GeneratePasswordFormModule,
    NewUserFormModule,
  ],
})
export class AdminModule {
}
