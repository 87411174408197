import { Injectable } from "@angular/core";
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResourceService } from "./resource.service";
import { Application } from 'src/app/shared/model/application.model';
import { Account } from '@model';

const ACCOUNTS_ENDPOINT = "accounts";

@Injectable()
export class AccountService {
  private accounts$: Observable<Account[]>;

  constructor(private resourceService: ResourceService<{ [string: string]: Account}>) {}

  getAccounts(): Observable<Account[]> {
    if (!this.accounts$) {
      this.accounts$ = this.resourceService.get(ACCOUNTS_ENDPOINT).pipe(
        map(value => Object.values(value)));
    }
    return this.accounts$;
  }
}
