import {Component, Inject, Input} from '@angular/core';
import {ApplicationService, GlobalAlertService, LoginService, PasswordService, UserService} from '@service';
import {FormControl, FormGroup} from '@angular/forms';
import {Credentials, User} from '@model';
import {CookieService} from 'ngx-cookie-service';
import {Router} from '@angular/router';
import {forkJoin} from 'rxjs';
import {Application} from '../../model/application.model';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'login-credentials-form',
  templateUrl: './credentials-form.component.html',
  styleUrls: ['./credentials-form.component.scss'],
})
export class CredentialsFormComponent {

  passwordShown = false;
  oldPasswordShown = false;
  resettingPassword = false;
  loggedIn = false;
  isAdmin = false;
  app: Application;

  @Input() loginForm: FormGroup;

  constructor(
    private loginService: LoginService,
    private passwordService: PasswordService,
    private cookieService: CookieService,
    private globalAlertService: GlobalAlertService,
    private userService: UserService,
    private applicationsService: ApplicationService,
    private route: Router,
    @Inject(DOCUMENT) private document
  ) {}

  get credentials(): Credentials {
    return { ...this.loginForm.getRawValue() } as Credentials;
  }

  get username(): string {
    return this.loginForm.get('username').value;
  }

  get passwordField() {
    return this.loginForm.get('password');
  }

  get oldPasswordField() {
    return this.loginForm.get('oldPassword');
  }

  get oldPassword(): string {
    return this.loginForm.get('oldPassword').value;
  }

  set oldPassword(password: string) {
    this.loginForm.get('oldPassword').setValue(password);
  }

  get password(): string {
    return this.loginForm.get('password').value;
  }

  toggleShowPassword() {
    this.passwordShown = !this.passwordShown;
  }

  toggleShowOldPassword() {
    this.oldPasswordShown = !this.oldPasswordShown;
  }

  toggleResetPassword() {
    this.resettingPassword = !this.resettingPassword;
    if (!this.resettingPassword) {
      this.oldPassword = null;
    }
  }

  login() {
    this.cookieService.delete('des_session', '/', '.denms.net');
    if (this.resettingPassword) {
      this.resettingPassword = false;
    }
    this.loginService.login(this.credentials).subscribe((results) => {
      // this.cookieService.set('des_session', results?.token, 1, '/', '.denms.net');
      if (!this.loginService.hasRedirect()) {
        this.globalAlertService.setSuccess('Login Successful...', 10000);
        this.loggedIn = true;
        this.handleLogin();
      } else {
        this.loginService.redirect();
      }
    });
  }

  handleLogin() {
    forkJoin(
      this.userService.getUserDetails(),
      this.applicationsService.getApplications()
    ).subscribe((value) => {
      const user = value[0];
      const applications = value[1];
      const matchedApp = applications.find((app) => app.id === user.homeApp.toUpperCase());

      if (user.permissions.hasOwnProperty('identitySuperAdmin')) {
        this.isAdmin = true;
      }

      if (matchedApp) {
        this.app = matchedApp
      }

      if (matchedApp && !this.isAdmin) {
        this.document.location.href = matchedApp.url;
      }

      if (!this.isAdmin) {
        this.globalAlertService.setError('No home application configured...You are now officially stranded.', 10000);
      }
    });
  }

  resetPassword() {
    if (!this.resettingPassword) {
      return;
    }
    this.passwordService.resetPassword(this.credentials).subscribe((results) => {
      this.globalAlertService.setSuccess("Successfully reset password...");
      this.toggleResetPassword();
    });
  }

  passwordValid() {
    return this.password && this.oldPassword && this.username
  }
}
