import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { toCamel, toSnake } from "convert-keys";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { CookieService } from "./cookie.service";

@Injectable()
export class ResourceService<T> {
  apiLocation: string;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService
  ) {
    this.apiLocation = "/api/v1";
  }

  private static handleError(error: string): Observable<never> {
    return throwError(() => {
      console.log(error);
      return new Error(`${error}`)
    });
  }

  get(
    path: string,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T
  ): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError)
      );
  }

  getById(
    path: string,
    id: string | number,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T
  ): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}/${id}`, { params })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError)
      );
  }

  post(
    path: string,
    resource: T | Partial<T>,
    params?: HttpParams,
    mappingCallback?: (response: unknown) => T
  ): Observable<T> {
    return this.httpClient
      .post<T>(`${this.apiLocation}/${path}`, toSnake(resource), {
        params,
      })
      .pipe(
        map(item => this.mapResponse(item, mappingCallback)),
        catchError(ResourceService.handleError)
      );
  }

  private mapResponse(item, mappingCallback): T {
    const data = item["data"];
    if (!mappingCallback) {
      if (typeof data !== "object") {
        return data;
      }
      return toCamel<T>(data);
    }
    return toCamel<T>(mappingCallback(data));
  }
}
